import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import axios from "axios";
import useSWR from "swr";

import { FormikAsyncSelect } from ".";
import { usePermission } from "../../hooks";
import permissions from "../../_common/permissions";

const fetcher = url => axios.get(url).then(res => res.data);

function AsyncSelectSubOrigem({ name, origem, ...props }) {
  const { data: subOrigens, mutate: recarregarLista } = useSWR(
    "/api/pipeline/suborigem/listar?somenteAtivos=true",
    fetcher
  );
  const nomeCampo = props.field.name;
  const labelCampo = props.label;
  const intl = useIntl();
  const permissao = usePermission(permissions.ORIGEM_SUBORIGEM);
  const { values, setFieldValue } = useFormikContext();
  const [lista, setLista] = useState([]);

  const handleChangeSubOrigem = (e, subOrigem) => {
    if (subOrigem) {
      const valor = subOrigem.id;
      setFieldValue("subOrigem.id", valor);
    }
  };

  useEffect(
    () => {
      if (subOrigens) setLista(subOrigens.filter(({ origemId }) => origemId === origem));
    },
    [origem, subOrigens]
  );

  return (
    <FormikAsyncSelect
      name={nomeCampo}
      id={nomeCampo}
      options={lista}
      value={values.subOrigem.id}
      label={labelCampo}
      onChange={handleChangeSubOrigem}
      criarNovo={permissao}
      urlCriarNovo={"/spotter/configuracoes/padroes"}
      fullWidth
      funcaoRecarregar={recarregarLista}
      noOptionText={intl.formatMessage({ defaultMessage: "Nenhuma" })}
      {...props}
    />
  );
}

AsyncSelectSubOrigem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  origem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  field: PropTypes.object,
};

export default AsyncSelectSubOrigem;
