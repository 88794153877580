import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import { AsyncWithSearch } from "../../AsyncSelect";

function SelectEtapa({ incluirDescarte, listarTodosFunis, exibirNomeFunil, funisAtivos, ...props }) {
  const intl = useIntl();
  const URL = `/api/pipeline/etapa/listar?funilid=null&incluirDescarte=${incluirDescarte}&todosFunis=${listarTodosFunis}&exibirNomeFunil=${exibirNomeFunil}&funisAtivos=${funisAtivos}`;
  return (
    <AsyncWithSearch
      label={intl.formatMessage({ defaultMessage: "Etapa" })}
      url={URL}
      getLabel={item => item.nome}
      {...props}
    />
  );
}
SelectEtapa.propTypes = {
  incluirDescarte: PropTypes.bool,
  listarTodosFunis: PropTypes.bool,
  exibirNomeFunil: PropTypes.bool,
  funisAtivos: PropTypes.bool,
};

export default SelectEtapa;
