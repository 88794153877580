import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  withStyles,
  Typography,
  Card as MCard,
  CardHeader,
  CardContent,
  CardActions,
} from "@material-ui/core";

import { Flex } from "../_common";
import Tile from "./Tile";
import DashboardInfo from "../pages/dashboard/components/DashboardInfo";

Card.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  id: PropTypes.string,
  relatorioId: PropTypes.number,
};

function Card({
  header,
  subheader,
  children,
  height,
  width,
  content,
  footer,
  loading,
  id,
  classes,
  relatorioId = null,
}) {
  return (
    <Tile height={height} width={width} id={id}>
      <MCard className={classes.root}>
        <CardHeader
          disableTypography
          title={
            <Flex>
              <Typography inline color="textPrimary" className={classes.title}>
                {header}
              </Typography>
              {relatorioId && <DashboardInfo titulo={header} relatorio={relatorioId} />}
            </Flex>
          }
          subheader={
            subheader ? (
              <Typography inline color="textSecondary" className={classes.title}>
                &nbsp;| {subheader}
              </Typography>
            ) : null
          }
          className={classes.header}
        />
        <CardContent className={classes.content}>
          {loading && (
            <Flex center style={{ height: "100%" }}>
              <CircularProgress />
            </Flex>
          )}
          {!loading && (
            <Fragment>
              {children || (
                <Flex justifyContent="center" alignItems="center" style={{ flexGrow: 1 }}>
                  <Typography style={{ fontSize: 42, paddingLeft: 8, paddingRight: 9, color: "rgba(0,0,0,0.56)" }}>
                    {content}
                  </Typography>
                </Flex>
              )}
            </Fragment>
          )}
        </CardContent>

        {!loading && (
          <CardActions className={classes.footer}>
            <Typography color="textSecondary" variant="caption">
              {footer}
            </Typography>
          </CardActions>
        )}
      </MCard>
    </Tile>
  );
}

export default withStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    margin: 0,
  },
  title: { fontSize: "12px" },
  header: {
    padding: 6,
    minHeight: 0,
  },
  content: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: 6,
  },
}))(Card);
