import React from "react";
import { useIntl } from "react-intl";

import { AsyncWithSearch } from "../../AsyncSelect";
import { useTipoPlanoSpotter } from "../../../hooks";

const URL = "/api/pipeline/usuario1/listar?tipo=vendedor&somenteAtivos=true";
function SelectVendedor(props) {
  const intl = useIntl();
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    <AsyncWithSearch
      label={
        isSpotterFull
          ? intl.formatMessage({ defaultMessage: "Vendedor" })
          : intl.formatMessage({ defaultMessage: "Usuário" })
      }
      url={URL}
      {...props}
    />
  );
}

export default SelectVendedor;
