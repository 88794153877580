import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";

import AsyncSelect from "./AsyncSelect";

FormikAsyncSelect.propTypes = { name: PropTypes.string, usarUseField: PropTypes.bool };

function FormikAsyncSelect({ name, usarUseField, ...props }) {
  const [field, meta] = useField({ name, ...props });

  if (usarUseField === false) return <AsyncSelect {...props} />;

  return <AsyncSelect {...field} {...meta} {...props} />;
}

export default FormikAsyncSelect;
