import React from "react";
import { useIntl } from "react-intl";
import AsyncSelect from "../../AsyncSelect";

function SelectOrganizacao(props) {
  const intl = useIntl();
  const URL = "/Api/Pipeline/Organizacao/ListarDescricao?somenteAtivos=false";

  return (
    <AsyncSelect label={intl.formatMessage({ defaultMessage: "Organização" })} url={URL} {...props} enableSearch />
  );
}

export default SelectOrganizacao;
