import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import axios from "axios";
import useSWR from "swr";
import { FormikAsyncSelect } from "../AsyncSelect";
import { usePermission } from "../../hooks";
import permissions from "../../_common/permissions";

const fetcher = url => axios.get(url).then(res => res.data);

function AsyncSelectMercado({ name, ...props }) {
  const { data: mercados, mutate: recarregarLista } = useSWR(
    "/api/pipeline/mercado/ListarMercado?SomenteAtivos=true",
    fetcher
  );
  const nomeCampo = props.field.name;
  const labelCampo = props.label;
  const permissao = usePermission(permissions.MERCADO);
  const { values, setFieldValue } = useFormikContext();

  const handleChangeMercado = (e, mercado) => {
    if (mercado !== undefined) {
      const valor = mercado.id;
      setFieldValue("mercado.id", valor);
    }
  };

  return (
    <FormikAsyncSelect
      name={nomeCampo}
      id={nomeCampo}
      options={mercados}
      value={values.mercado.id}
      label={labelCampo}
      onChange={handleChangeMercado}
      criarNovo={permissao}
      urlCriarNovo={"/spotter/configuracoes/padroes"}
      funcaoRecarregar={recarregarLista}
      fullWidth
    />
  );
}

AsyncSelectMercado.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  field: PropTypes.object,
};

export default AsyncSelectMercado;
