import React from "react";
import PropTypes from "prop-types";
import { Chip as MChip, Tooltip, withStyles } from "@material-ui/core";
import cn from "classnames";

function Chip({ tooltip, label, status, classes }) {
  return (
    <Tooltip title={tooltip || ""}>
      <MChip
        size="small"
        label={label}
        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
        className={cn(classes.chip, [{}, classes.red, classes.yellow, classes.gray, classes.green][status])}
      />
    </Tooltip>
  );
}

Chip.propTypes = {
  tooltip: PropTypes.string,
  label: PropTypes.string,
  status: PropTypes.number,
  classes: PropTypes.object,
};

export default withStyles(({ palette }) => ({
  tooltip: {},
  chip: {},
  chipLabel: { color: "white", paddingLeft: 4, paddingRight: 4 },
  chipRoot: { fontSize: 11, height: 16, borderRadius: 4 },
  red: { backgroundColor: palette.status.red },
  yellow: { backgroundColor: palette.status.yellow },
  green: { backgroundColor: palette.status.green },
  gray: { backgroundColor: palette.status.gray },
}))(Chip);
