import React from "react";
import PropTypes from "prop-types";
import NumberMask from "react-number-format";

CurrencyInput.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

function CurrencyInput({ inputRef, ...rest }) {
  return (
    <NumberMask
      getInputRef={inputRef}
      decimalSeparator=","
      decimalScale={2}
      allowNegative={false}
      fixedDecimalScale
      allowEmptyFormatting
      prefix="R$ "
      {...rest}
    />
  );
}

export default React.memo(CurrencyInput);
