import React from "react";
import { useIntl } from "react-intl";

import { AsyncWithSearch } from "../../AsyncSelect";

function SelectCidade(props) {
  const intl = useIntl();
  const URL = "/api/pipeline/cidade/listar";

  return <AsyncWithSearch label={intl.formatMessage({ defaultMessage: "Cidade" })} url={URL} {...props} />;
}

export default SelectCidade;
