import React from "react";
import PropTypes from "prop-types";
import { withStyles, Typography, Tooltip, Link } from "@material-ui/core";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";

import { defineMessage } from "react-intl";
import { copyToClipboard } from "../_common/utils/string";
import { Flex, createSnackbar } from "../_common";

function copiar(value, ddi) {
  const validatedValue = ddi ? `+${ddi} ${value}` : value;
  copyToClipboard(validatedValue);
  createSnackbar(defineMessage({ defaultMessage: "Copiado" }));
}

function HoverActions({
  value,
  copyValue = value,
  ddi,
  classes,
  children,
  tooltip,
  link = false,
  linkLabel = null,
  label = null,
}) {
  return (
    <Flex className={classes.root} justifyContent="space-between">
      {!link && (
        <>
          {label && <Typography className={classes.truncate}>{label}</Typography>}
          {!label && (
            <Typography color="primary" variant="caption" className={classes.truncate}>
              {value}
            </Typography>
          )}
        </>
      )}
      {link && (
        <Link href={value} target="_blank" rel="noreferrer">
          {linkLabel !== null ? linkLabel : value}
        </Link>
      )}

      <Flex className={classes.actions}>
        {children}

        {tooltip ? (
          <Tooltip title={tooltip} placement="bottom">
            <FileCopyOutlined
              id="fileCopyOutlined"
              onClick={() => copiar(copyValue, ddi)}
              fontSize="small"
              color="primary"
            />
          </Tooltip>
        ) : (
          <FileCopyOutlined
            id="fileCopyOutlined"
            onClick={() => copiar(copyValue, ddi)}
            fontSize="small"
            color="primary"
          />
        )}
      </Flex>
    </Flex>
  );
}

HoverActions.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
  copyValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ddi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  classes: PropTypes.object,
  tooltip: PropTypes.string,
  link: PropTypes.bool,
  linkLabel: PropTypes.string,
  label: PropTypes.string,
};

export default withStyles(theme => ({
  root: {
    padding: "8px 20px",
    "&:hover": {
      backgroundColor: theme.palette.backgroundRowHover,
    },
    "&:hover $actions": {
      visibility: "visible",
    },
  },
  actions: {
    visibility: "hidden",
    "& > *": {
      marginRight: 6,
      fontSize: 18,
      cursor: "pointer",
    },
  },
  icon: { marginRight: 6, fontSize: 18, cursor: "pointer" },
  truncate: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
  flag: {},
}))(HoverActions);
