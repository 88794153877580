import React from "react";
import { useField } from "formik";

import SelectDor from "./SelectDor";

function FormikSelectDor(props) {
  const [field, meta] = useField({ name: "dorId", ...props });

  return <SelectDor {...field} {...meta} {...props} />;
}

export default FormikSelectDor;
