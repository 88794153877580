import { useState, useEffect, cloneElement } from "react";
import { PropTypes } from "prop-types";

function Debounced({ value, onChange, timeout, children }) {
  const [text, setText] = useState(value);

  useEffect(
    () => {
      const st = setTimeout(() => {
        onChange(text);
      }, timeout);
      return () => clearTimeout(st);
    },
    [text, timeout]
  );
  return cloneElement(children, { value: text, onChange: e => setText(e.target.value) });
}

Debounced.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  timeout: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.element.isRequired,
};

export default Debounced;
