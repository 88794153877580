import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

TextButton.propTypes = { label: PropTypes.node.isRequired };

function TextButton({ label, ...props }) {
  return <Button {...props}>{label}</Button>;
}

export default TextButton;
