import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { InputAdornment, withStyles } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import { Flex } from "../_common";

function ClearInput({ name, label, hasValue, clearValue, classes, ...props }) {
  return (
    <Flex className={classes.root}>
      <Field
        component={TextField}
        name={name}
        id={name}
        label={label}
        fullWidth
        InputProps={{
          endAdornment: hasValue && (
            <InputAdornment>
              <Clear
                style={{
                  cursor: "pointer",
                  fontSize: 14,
                  border: "1px solid rgb(157,157,157)",
                  borderRadius: "50%",
                  color: "rgb(157,157,157)",
                }}
                onClick={clearValue}
                name={name}
              />
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </Flex>
  );
}

ClearInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  hasValue: PropTypes.bool,
  clearValue: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles({ root: {} })(ClearInput);
