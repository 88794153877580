import React from "react";
import { useField } from "formik";

import SelectPreVendedor from "./SelectPreVendedor";

function FormikSelectPreVendedor(props) {
  const [field, meta] = useField({ name: "preVendedorId", ...props });

  return <SelectPreVendedor {...field} {...meta} {...props} />;
}

export default FormikSelectPreVendedor;
