import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import axios from "axios";
import useSWR from "swr";
import { FormikAsyncSelect } from "../AsyncSelect";
import { usePermission } from "../../hooks";
import permissions from "../../_common/permissions";

const fetcher = url => axios.get(url).then(res => res.data);

function AsyncSelectOrigem({ name, ...props }) {
  const { data: origens, mutate: recarregarLista } = useSWR("/api/pipeline/origem/listar?somenteAtivos=true", fetcher);
  const nomeCampo = props.field.name;
  const labelCampo = props.label;
  const permissao = usePermission(permissions.ORIGEM_SUBORIGEM);
  const { values, setFieldValue } = useFormikContext();

  const handleChangeOrigem = (e, origem) => {
    if (origem !== undefined) {
      const valor = origem.id;
      props.desbloquearSubOrigem(false);
      setFieldValue("origem.id", valor);
      setFieldValue("subOrigem.id", "");
    }
  };

  return (
    <FormikAsyncSelect
      name={nomeCampo}
      id={nomeCampo}
      options={origens}
      value={values.origem.id}
      label={labelCampo}
      onChange={handleChangeOrigem}
      criarNovo={permissao}
      urlCriarNovo={"/spotter/configuracoes/padroes"}
      funcaoRecarregar={recarregarLista}
      fullWidth
    />
  );
}

AsyncSelectOrigem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  field: PropTypes.object,
  desbloquearSubOrigem: PropTypes.func,
};

export default AsyncSelectOrigem;
