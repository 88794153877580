import React, { useRef, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Menu, IconButton } from "@material-ui/core";

export default function AnchorMenu({ icon, children, ...props }) {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  if (!children) return null;

  const childrenWithProp = React.Children.map(children, child =>
    React.cloneElement(child, { closeMenu: () => setOpen(false) })
  );

  return (
    <Fragment>
      <IconButton buttonRef={ref} onClick={() => setOpen(true)} {...props}>
        {icon}
      </IconButton>
      <Menu disableAutoFocusItem keepMounted anchorEl={ref.current} open={open} onClose={() => setOpen(false)}>
        {childrenWithProp}
      </Menu>
    </Fragment>
  );
}

AnchorMenu.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.object,
};
