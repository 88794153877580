import React from "react";
import { useField } from "formik";

import SelectProduto from "./SelectProduto";

function FormikSelectProduto(props) {
  const [field, meta] = useField({ name: "produtoId", ...props });
  return <SelectProduto {...field} {...meta} {...props} />;
}

export default FormikSelectProduto;
