import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

MenuButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
};

function MenuButton({ icon, label, ...props }) {
  return (
    <Button {...props}>
      {icon}
      {label}
    </Button>
  );
}

export default MenuButton;
