import React from "react";
import PropTypes from "prop-types";

import IconButton from "./IconButton";
import MenuButton from "./MenuButton";
import TextButton from "./TextButton";
import TypographyButton from "./TypographyButton";
import Button from "./Button";

ActionButton.propTypes = { type: PropTypes.oneOf(["icon", "menu", "text", "button", "typography"]) };

function ActionButton({ type = "icon", ...props }) {
  let Child = null;
  if (type === "icon") Child = IconButton;
  if (type === "menu") Child = MenuButton;
  if (type === "text") Child = TextButton;
  if (type === "button") Child = Button;
  if (type === "typography") Child = TypographyButton;

  return <Child {...props} />;
}

export default ActionButton;
