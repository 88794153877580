import React from "react";
import { useIntl } from "react-intl";

import { AsyncWithSearch } from "../../AsyncSelect";

function SelectEstado(props) {
  const intl = useIntl();
  const URL = "/api/pipeline/estado/listar";
  return (
    <AsyncWithSearch id="select-estado" label={intl.formatMessage({ defaultMessage: "Estado" })} url={URL} {...props} />
  );
}

export default SelectEstado;
