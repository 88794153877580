import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import MailOutlined from "@material-ui/icons/MailOutlined";

import {
  abrirEscreverEmail as abrirEscreverEmailAction,
  adicionarEmailTo as adicionarEmailToAction,
} from "../store/reducers/emails/emailsActions";
import Comp from "./HoverActions";
import { useUsuario } from "../hooks";
import { isAutenticado } from "../store/reducers/emails/emails";

function Email({ value, classes, history }) {
  const { isPVOrVendedor } = useUsuario();
  const podeEnviarEmail = useSelector(isAutenticado);

  const dispatch = useDispatch();

  function abrirEscreverEmail() {
    if (isPVOrVendedor && !podeEnviarEmail) history.push("/spotter/central-emails");
    else {
      dispatch(adicionarEmailToAction(value));
      dispatch(abrirEscreverEmailAction());
    }
  }

  return (
    <React.Fragment>
      <Comp value={value.email} classes={classes}>
        {isPVOrVendedor && <MailOutlined onClick={abrirEscreverEmail} fontSize="small" color="primary" />}
      </Comp>
    </React.Fragment>
  );
}

Email.propTypes = {
  value: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(Email);
