import React from "react";
import { useField } from "formik";

import SelectMercado from "./SelectMercado";

function FormikSelectMercado(props) {
  const [field, meta] = useField({ name: "mercadoId", ...props });

  return <SelectMercado {...field} {...meta} {...props} />;
}

export default FormikSelectMercado;
