import React from "react";
import { useField } from "formik";

import SelectSubOrigem from "./SelectSubOrigem";

function FormikSelectSubOrigem(props) {
  const [field, meta] = useField({ name: "subOrigemId", ...props });

  return <SelectSubOrigem {...field} {...meta} {...props} />;
}

export default FormikSelectSubOrigem;
