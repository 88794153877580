import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, IconButton, Badge } from "@material-ui/core";
import FilterList from "@material-ui/icons/Tune";
import { useIntl } from "react-intl";

import ModalBuscaAvancada from "./ModalBuscaAvancada";
import { createSnackbar, DialogTitleClose } from "../../_common";

function BuscaAvancada({ onApply, onSave, regraSegmentacao = {}, limpar, ...props }) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const filtroSalvo = JSON.parse(localStorage.getItem(`BuscaAvancadaSalva`));
  const filtro = filtroSalvo ?? regraSegmentacao;

  // sends the values up after saving, cannot send null
  function save(busca) {
    createSnackbar(intl.formatMessage({ defaultMessage: "Busca {nome} salva com sucesso" }, { nome: busca.nome }));
    setOpen(false);
    onSave(busca);
  }

  // sends the values up without saving, can send null
  function apply(values) {
    setOpen(false);
    onApply(values);
  }

  const temParametrosSelecionados = filtro && Object.keys(filtro.parametros ?? {}).length > 1;
  const quantidadeParametrosSelecionados = filtro && Object.keys(filtro.parametros ?? {}).length - 1;

  return (
    <Fragment>
      <div id="divBotaoFiltro" style={{ paddingLeft: "10px" }}>
        <IconButton
          size="large"
          onClick={() => setOpen(true)}
          id="buttonFilterBuscaAvancada"
          style={{
            borderRadius: "23px",
            width: "27px",
            height: "27px",
            border: temParametrosSelecionados ? "1px solid #457AB7" : "1px solid #0000008A",
            marginRight: 8,
          }}
        >
          <Badge badgeContent={quantidadeParametrosSelecionados} color="primary" invisible={!temParametrosSelecionados}>
            <FilterList
              style={{
                marginTop: "1px",
                fontSize: "17px",
                color: temParametrosSelecionados ? "#457AB7" : "#0000008A",
              }}
            />
          </Badge>
        </IconButton>
      </div>

      <Dialog maxWidth="lg" fullWidth open={open} onClose={() => setOpen(false)} onBackdropClick="false">
        <DialogTitleClose onClose={() => setOpen(false)}>
          {intl.formatMessage({ defaultMessage: "Seleção Customizada" })}
        </DialogTitleClose>

        <ModalBuscaAvancada
          regraSegmentacao={filtro}
          onApply={apply}
          onClear={() => limpar()}
          onSave={save}
          onClose={() => setOpen(false)}
          {...props}
        />
      </Dialog>
    </Fragment>
  );
}

BuscaAvancada.propTypes = {
  onApply: PropTypes.func,
  onSave: PropTypes.func,
  limpar: PropTypes.func,
  regraSegmentacao: PropTypes.object,
  buscas: PropTypes.array,
};

export default BuscaAvancada;
