import React, { useEffect, useState, useRef } from "react";
import useSWR from "swr";
import PropTypes from "prop-types";
import { Button, MenuItem, Menu, withStyles } from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { useIntl } from "react-intl";

import { Flex } from "../../_common";
import { useDebounce, useUsuario } from "../../hooks";
import BuscaAvancada from "./BuscaAvancada";
import ExcluirRegra from "../../pages/configuracoes/RegrasSegmentacao/ExcluirRegra";
import EditarRegra from "../../pages/configuracoes/RegrasSegmentacao/EditarRegra";
import { messagesBusca } from "../../pages/configuracoes/RegrasSegmentacao/constants";
import {
  DEFAULT_REGRA,
  clearEmptyFields,
} from "../../pages/configuracoes/RegrasSegmentacao/EditarRegra/useEditarRegra";

Buscas.propTypes = { onChange: PropTypes.func };
function Buscas({ onChange, classes }) {
  const intl = useIntl();
  const [regraSalva, setRegraSalva] = useState(""); // { id, nome } da pesquisa selecionada
  const [regraSelecionada, setRegraSelecionada] = useState(); // parametros da regra selecionada/editada
  const [possuiRegra, setPossuiRegra] = useState();
  const [editando, setEditando] = useState(false);
  const regraDebounce = useDebounce(regraSelecionada, 200);
  const { data, mutate: mutateRegras } = useSWR("/api/pipeline/SegmentacaoRegra/Listar");
  const { id: usuarioId } = useUsuario();
  const regras = data?.filter(r => r.usuarioId === usuarioId);
  const filtroSalvo = JSON.parse(localStorage.getItem(`BuscaAvancadaSalva`));

  const { data: regraSegmentacao } = useSWR(
    regraSalva?.id ? `/api/pipeline/SegmentacaoRegra/Buscar?id=${regraSalva.id}` : null
  );

  const [open, setOpen] = useState(false);
  const menuAnchorRef = useRef();

  useEffect(
    () => {
      onChange(regraDebounce?.parametros);
    },
    [regraDebounce, onChange]
  );

  useEffect(
    () => {
      if (regraSegmentacao && !editando) {
        setRegraSelecionada(regraSegmentacao);
        setPossuiRegra(true);
        if (regraSalva) localStorage.setItem(`BuscaAvancadaSalva`, JSON.stringify(regraSegmentacao));
      }

      if (filtroSalvo) setPossuiRegra(true);
    },
    [regraSegmentacao]
  );

  function selectRegra(regra) {
    setRegraSalva(regra);
    if (!regra && regraSelecionada?.id) {
      setRegraSelecionada();
    }

    if (regra === undefined) {
      localStorage.removeItem(`BuscaAvancadaSalva`);
      setRegraSelecionada();
    }

    setOpen(false);
  }

  function save(regra) {
    setRegraSalva(regra);
    setRegraSelecionada(regra);
    setPossuiRegra(true);
  }

  function apply(regra) {
    setRegraSalva();
    setRegraSelecionada(regra);
    if (regra && Object.keys(regra.parametros ?? {}).length > 1) setPossuiRegra(true);
  }

  const onDelete = id => success => {
    if (!success) return;
    mutateRegras(r => r.filter(re => re.id !== id), false);
    if (regraSelecionada?.id === id) selectRegra();
    setOpen(false);
  };

  const limpar = async () => {
    apply(DEFAULT_REGRA);
    apply(clearEmptyFields(DEFAULT_REGRA));
    localStorage.removeItem(`BuscaAvancadaSalva`);
    setPossuiRegra(false);
  };

  return (
    <Flex alignItems="center">
      <Button
        id="open-minhasBuscas"
        className={classes.savedButton}
        onClick={() => setOpen(true)}
        buttonRef={menuAnchorRef}
      >
        <span className={classes.buttonText}>
          {regraSalva ? regraSalva.nome : intl.formatMessage({ defaultMessage: "Seleções Salvas" })}
        </span>
        <ArrowDropDown />
      </Button>

      <Menu keepMounted open={open} anchorEl={menuAnchorRef.current} onClose={() => setOpen(false)}>
        <MenuItem id="menuItem-buscas-nenhum" onClick={() => selectRegra()}>
          {intl.formatMessage({ defaultMessage: "Nenhum filtro" })}
        </MenuItem>

        {regras?.map(r => (
          <MenuItem id={`menu-regra-${r.id}`} className={classes.menuItem} key={r.id} onClick={() => selectRegra(r)}>
            <Flex justifyContent="space-between" alignItems="center" style={{ minWidth: 200 }}>
              {r.nome}
              <Flex className={classes.actions}>
                <EditarRegra
                  type="icon"
                  id={r.id}
                  onSave={save}
                  customMessages={messagesBusca}
                  setEditando={setEditando}
                />
                <ExcluirRegra id={r.id} onDelete={onDelete(r.id)} customMessages={messagesBusca} />
              </Flex>
            </Flex>
          </MenuItem>
        ))}
      </Menu>

      <BuscaAvancada
        onApply={apply}
        onSave={save}
        regraSegmentacao={regraSelecionada}
        id={regraSalva?.id}
        limpar={limpar}
      />

      {possuiRegra && (
        <Button id="btnLimpar-ModalBuscaAvancada" onClick={limpar} style={{ paddingLeft: "20px" }}>
          {intl.formatMessage({ defaultMessage: "Limpar Seleção" })}
        </Button>
      )}
    </Flex>
  );
}

export default withStyles({
  savedButton: {
    width: 167,
    alignItems: "center",
  },
  actions: {
    visibility: "hidden",
  },
  menuItem: {
    "&:hover $actions": {
      visibility: "visible",
    },
  },
  buttonText: {
    width: 138,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    verticalAlign: "center",
  },
})(Buscas);
