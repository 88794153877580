import React from "react";
import { useIntl } from "react-intl";
import { AsyncWithSearch } from "../../AsyncSelect";

function SelectSubOrigem(props) {
  const intl = useIntl();
  const URL = "/api/pipeline/subOrigem/listar";

  const montarLabel = item =>
    item.descricaoAdicional
      ? intl.formatMessage(
          {
            defaultMessage: "{subOrigem} {span}",
          },
          {
            span: <span style={{ color: "gray", fontSize: 15 }}>| {item.descricaoAdicional}</span>,
            subOrigem: item.descricao,
          }
        )
      : item.descricao;

  return (
    <AsyncWithSearch
      id="select-suborigem"
      label={intl.formatMessage({ defaultMessage: "Sub-Origem" })}
      url={URL}
      getInputLabel={item => item.descricao}
      getLabel={item => montarLabel(item)}
      {...props}
    />
  );
}

export default SelectSubOrigem;
