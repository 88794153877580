import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import useSWR from "swr";

import SelectCidade from "./SelectCidade";

FormikSelectCidade.propTypes = {
  estados: PropTypes.arrayOf(PropTypes.number),
  paises: PropTypes.arrayOf(PropTypes.number),
};
function FormikSelectCidade({ estados = [], paises = [], ...props }) {
  const [field, meta, helpers] = useField({ name: "cidadeId", ...props });
  const { data: options } = useSWR("/api/pipeline/cidade/listar");

  const cidades = useMemo(
    () => {
      const cidadesPorEstado = estados.length ? options?.filter(option => estados.includes(option.estadoId)) : options;
      const cidadesPorPais = paises.length
        ? cidadesPorEstado?.filter(option => paises.includes(option.paisId))
        : cidadesPorEstado;

      return cidadesPorPais;
    },
    [estados, paises, options]
  );

  useEffect(
    () => {
      if (!cidades) return;
      const valueArray = [].concat(field.value);
      const filtradas = valueArray.filter(cidade => cidades.some(({ id }) => id === cidade));
      if (filtradas.length !== valueArray.length)
        helpers.setValue(Array.isArray(field.value) ? filtradas : filtradas[0] ?? null);
    },
    [cidades, helpers.setValue]
  );

  return <SelectCidade {...field} {...meta} options={cidades} {...props} />;
}

export default FormikSelectCidade;
