import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import useSWR from "swr";

import SelectEtapa from "./SelectEtapa";

FormikSelectEtapa.propTypes = {
  funis: PropTypes.arrayOf(PropTypes.number),
  exibirNomeFunil: PropTypes.bool,
  incluirDescarte: PropTypes.bool,
};
function FormikSelectEtapa({ funis = [], incluirDescarte = false, ...props }) {
  const [field, meta, helpers] = useField({ name: "etapaId", ...props });
  const { data: options } = useSWR(
    `/api/pipeline/Etapa/ListarTodas?exibirNomeFunil=${props.exibirNomeFunil ??
      false}&incluirDescarte=${incluirDescarte}`
  );

  const etapas = useMemo(() => (funis?.length ? options?.filter(option => funis.includes(option.funilId)) : options), [
    funis,
    options,
  ]);

  useEffect(
    () => {
      if (!etapas) return;
      const valueArray = [].concat(field.value);
      const filtradas = valueArray.filter(etapa => etapas.some(({ id }) => id === etapa));
      if (filtradas.length !== valueArray.length)
        helpers.setValue(Array.isArray(field.value) ? filtradas : filtradas[0] ?? null);
    },
    [etapas, helpers.setValue]
  );

  return <SelectEtapa {...field} {...meta} options={etapas} {...props} />;
}

export default FormikSelectEtapa;
