import React from "react";
import { useIntl } from "react-intl";

import { CONFIRMACAO_REUNIAO, LABELS_CONFIRMACAO_REUNIAO } from "../../../_common/constantes";
import { AsyncWithSearch } from "../../AsyncSelect";

export default function SelectConfirmacaoReuniao(props) {
  const intl = useIntl();

  const OPCOES = [
    {
      id: CONFIRMACAO_REUNIAO.CONFIRMADAS,
      descricao: intl.formatMessage(LABELS_CONFIRMACAO_REUNIAO[CONFIRMACAO_REUNIAO.CONFIRMADAS]),
    },
    {
      id: CONFIRMACAO_REUNIAO.NAO_CONFIRMADAS,
      descricao: intl.formatMessage(LABELS_CONFIRMACAO_REUNIAO[CONFIRMACAO_REUNIAO.NAO_CONFIRMADAS]),
    },
  ];

  return (
    <AsyncWithSearch
      label={intl.formatMessage({ defaultMessage: "Confirmação de Reunião" })}
      options={OPCOES}
      {...props}
    />
  );
}
