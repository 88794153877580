import { useState } from "react";
import { filterByTermInProps } from "../../_common/utils/fp";

export default function useSearch(enableSearch, data) {
  const [searchTerm, setSearchTerm] = useState("");
  const isSearching = enableSearch && !!searchTerm;
  const filterFn = filterByTermInProps(searchTerm, [
    "descricao",
    "descricaoAdicional",
    "nome",
    "email",
    "value",
    "search",
  ]);

  return {
    filteredItems: isSearching ? filterFn(data) : data,
    searchTerm,
    setSearchTerm,
    isSearching,
  };
}
