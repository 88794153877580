import React from "react";

import socket, { eActions } from "../../websocket/socket";
import { objectKeysToCamelCase } from "../../_common";
import criarNotificacaoBrowser from "../../_common/utils/notification/criarNotificacaoBrowser";
import { ENVIADOS } from "../../pages/central-emails/constantes";
import { appIntl } from "../../_common/containers/ConnectedIntlProvider";
import exactIconImage from "../../_common/icones/Exact_Icon.png";

const notificarLembreteAtividade = message => {
  try {
    const { titulo, descricao, leadId, atividadeId } = objectKeysToCamelCase(message);

    const opcoes = { body: descricao, tag: Number.parseInt(leadId, 10) };

    let eventos = {};

    if (atividadeId || leadId) {
      eventos = {
        onClick: () => {
          const rota = leadId ? `/spotter/detalhes-lead/${leadId}` : `/spotter/atividades/`;
          const url = window.location.origin + rota;
          window.open(url);
        },
      };
    }

    criarNotificacaoBrowser(titulo, opcoes, eventos, exactIconImage);
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }
};

const notificarLeituraCentralEmails = message => {
  try {
    const intl = appIntl();

    const { assunto, id, threadId } = objectKeysToCamelCase(message);

    const opcoes = { body: assunto, tag: Number.parseInt(id, 10) };

    let eventos = {};

    eventos = {
      onClick: () => {
        const rota = `/spotter/central-emails/${ENVIADOS}/${threadId}`;
        const url = window.location.origin + rota;
        window.open(url);
      },
    };

    criarNotificacaoBrowser(
      intl.formatMessage({ defaultMessage: "E-mail aberto" }),
      opcoes,
      eventos,
      "/images/Notificacoes/email.png"
    );
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }
};

const sugerirConclusaoAtividade = detail => {
  document.dispatchEvent(
    new CustomEvent("SpotterSugerirConclusaoAtividade", {
      detail: objectKeysToCamelCase(detail),
    })
  );
};

function HubNotificacoes() {
  React.useEffect(() => {
    socket.on(eActions.LEMBRETE_ATIVIDADE_V2, notificarLembreteAtividade);
    socket.on(eActions.NOTIFICACAO_LEITURA_CENTRAL_EMAILS, notificarLeituraCentralEmails);
    socket.on(eActions.SUGERIR_CONCLUSAO_ATIVIDADE, sugerirConclusaoAtividade);

    return () => {
      socket.off(eActions.LEMBRETE_ATIVIDADE_V2, notificarLembreteAtividade);
      socket.off(eActions.NOTIFICACAO_LEITURA_CENTRAL_EMAILS, notificarLeituraCentralEmails);
      socket.off(eActions.SUGERIR_CONCLUSAO_ATIVIDADE, sugerirConclusaoAtividade);
    };
  }, []);

  return null;
}

export default HubNotificacoes;
