import React from "react";
import { useField } from "formik";

import SelectOrigem from "./SelectOrigem";

function FormikSelectOrigem(props) {
  const [field, meta] = useField({ name: "origemId", ...props });

  return <SelectOrigem {...field} {...meta} {...props} />;
}

export default FormikSelectOrigem;
