import React from "react";
import PropTypes from "prop-types";
import { set } from "dot-prop-immutable";
import { MuiThemeProvider } from "@material-ui/core";

FixTableHeaders.propTypes = { children: PropTypes.node };
function FixTableHeaders({ children }) {
  return <MuiThemeProvider theme={overrideTheme}>{children}</MuiThemeProvider>;
}

const styles = {
  MuiTable: {
    root: {
      height: "100%",
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: "none",
      position: "relative",
      left: 0,
    },
    head: {
      position: "sticky",
      top: 0,
      backgroundColor: "inherit",
      zIndex: 1,
      "&:first-child": {
        zIndex: 2,
      },
      "&:after": {
        position: "absolute",
        content: "''",
        right: 0,
        bottom: 0,
        width: "100%",
        borderBottom: "1px solid #e0e0e0",
      },
    },
    body: {
      backgroundColor: "inherit",
      "&:first-child": {
        position: "sticky",
        left: 0,
        zIndex: 1,
      },
    },
  },
  MuiTableRow: {
    root: {
      height: 56,
      backgroundColor: "white",
    },
    hover: {
      "&:hover": { backgroundColor: "#e0e0e0 !important" },
    },
  },
};

const overrideTheme = theme => {
  let newTheme = set(theme, "overrides.MuiTableCell", styles.MuiTableCell);
  newTheme = set(newTheme, "overrides.MuiTableRow", styles.MuiTableRow);
  newTheme = set(newTheme, "overrides.MuiTable", styles.MuiTable);
  return newTheme;
};

export default FixTableHeaders;
