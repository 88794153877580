import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { withStyles, CircularProgress } from "@material-ui/core";
import { get } from "dot-prop-immutable";

import { useIntl } from "react-intl";
import TextMaskCpfCnpj from "./TextMaskCpfCnpj";

const CPF_LENGTH = 11;
const CNPJ_LENGTH = 14;

const isCPF = (value = "") => value.trim().length === CPF_LENGTH;
const isCNPJ = (value = "") => value.trim().length === CNPJ_LENGTH;
const isCpfCnpj = value => isCPF(value) || isCNPJ(value);

function CampoCpfCnpj({
  name,
  config = {},
  isLoading,
  showSemelhantes,
  SemelhantesComponent = null,
  onBlur,
  classes,
  ...props
}) {
  const { values, handleBlur, setFieldError, ...bag } = useFormikContext();
  const error = get(bag.errors, name);
  const touched = get(bag.touched, name);
  const hasError = !!error && touched;
  const value = get(values, name, "");
  const intl = useIntl();

  const getHelperText = () => {
    const val = value || "";
    if (showSemelhantes && isCpfCnpj(val.replace(/\D/g, ""))) {
      return SemelhantesComponent;
    }

    if (hasError) {
      return get(bag.errors, name);
    }

    if (hasError && config.obrigatorio) {
      return intl.formatMessage({ defaultMessage: "Campo obrigatório *" });
    }

    return "";
  };

  const validate = useCallback((valor = "") => {
    const { obrigatorio, restringirUnico } = config;

    const cpfCnpjSemMask = valor.replace(/\D/g, "").trim();

    if (obrigatorio && !cpfCnpjSemMask.length) {
      return intl.formatMessage({ defaultMessage: "Campo obrigatório *" });
    }

    if (cpfCnpjSemMask.length && !isCpfCnpj(cpfCnpjSemMask)) {
      return "Número inválido";
    }

    if (restringirUnico && showSemelhantes > 0) {
      return "CPF/CNPJ deve ser único na base.";
    }

    return undefined;
  });

  const inputHasError = hasError || !!showSemelhantes;

  return (
    <Field
      name={name}
      label={`CPF/CNPJ${config.obrigatorio ? "*" : ""}`}
      InputProps={{
        endAdornment: isLoading ? <CircularProgress color="inherit" size={20} /> : undefined,
        error: showSemelhantes || hasError,
        inputComponent: TextMaskCpfCnpj,
        onBlur,
        name,
      }}
      InputLabelProps={{
        error: inputHasError,
      }}
      FormHelperTextProps={{
        error: inputHasError,
      }}
      component={TextField}
      validate={validate}
      error={hasError}
      helperText={getHelperText()}
      {...props}
    />
  );
}

CampoCpfCnpj.propTypes = {
  name: PropTypes.string,
  habilitarEdicao: PropTypes.bool,
  config: PropTypes.shape({
    habilitar: PropTypes.bool,
    obrigatorio: PropTypes.bool,
    restringirUnico: PropTypes.bool,
  }),
  showSemelhantes: PropTypes.bool,
  SemelhantesComponent: PropTypes.node,
  onBlur: PropTypes.func,
  isLoading: PropTypes.bool,
  // Pode ser usado para bloquear o componente pai, caso seja obrigatório esperar pela validação dos leads semelhantes
  classes: PropTypes.object,
};

export default withStyles({})(CampoCpfCnpj);
