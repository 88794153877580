import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";

import { TIPO_CAMPO_PERSONALIZADO } from "../../../_common/constantes";
import TextMaskNumber from "../../../components/TextMaskNumber";
import AsyncSelect from "../../AsyncSelect";

function removeFieldProps(props) {
  const { initialError, initialTouched, initialValue, touched, validate, ...newProps } = props;
  return newProps;
}

function SelectCampoPersonalizado({ campo, ...props }) {
  const [valorFiltrado, setValorFiltrado] = useState([]);

  useEffect(
    () => {
      if (props.value) setValorFiltrado(props.value.split(",").map(i => parseInt(i, 10)));
    },
    [props.value]
  );

  const handleChange = event => {
    setValorFiltrado(event.target.value);
    props.onChange({ ...event, target: { ...event.target, value: event.target.value.toString() } });
  };
  const newProps = removeFieldProps(props);
  if (TIPO_CAMPO_PERSONALIZADO.isEscolha(campo.tipo))
    return (
      <AsyncSelect
        fullWidth
        enableSearch
        type={"multiple"}
        label={campo.nome}
        options={campo.opcoes}
        value={valorFiltrado}
        name={props.name}
        id={props.id}
        onChange={event => handleChange(event)}
      />
    );

  if (campo.formato)
    return (
      <TextField
        fullWidth
        label={campo.nome}
        InputProps={{
          inputComponent: InputMask,
          inputProps: { mask: campo.formato },
        }}
        {...newProps}
      />
    );

  return (
    <>
      {campo.tipo === TIPO_CAMPO_PERSONALIZADO.NUMERO ? (
        <TextField
          fullWidth
          label={campo.nome}
          InputProps={{
            inputComponent: TextMaskNumber,
            inputProps: { length: 8000 },
          }}
          {...newProps}
        />
      ) : (
        <TextField fullWidth label={campo.nome} {...newProps} />
      )}
    </>
  );
}

SelectCampoPersonalizado.propTypes = {
  campo: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
};

export default SelectCampoPersonalizado;
