import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";

TypographyButton.propTypes = {
  label: PropTypes.string.isRequired,
};

function TypographyButton({ label, ...props }) {
  return (
    <Button {...props} component={Typography} style={{ textTransform: "none" }}>
      {label}
    </Button>
  );
}

export default TypographyButton;
