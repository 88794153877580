import React from "react";
import { useField } from "formik";

import SelectOrganizacao from "./SelectOrganizacao";

function FormikSelectOrganizacao(props) {
  const [field, meta] = useField({ name: "organizacao", ...props });

  return <SelectOrganizacao {...field} {...meta} {...props} />;
}

export default FormikSelectOrganizacao;
