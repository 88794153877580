import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import axios from "axios";
import useSWR from "swr";
import { useTipoPlanoSpotter, useUsuario } from "../../hooks";

import { FormikAsyncSelect } from "../AsyncSelect";

const fetcher = url => axios.get(url).then(res => res.data);

function AsyncSelectFunil({ name, ...props }) {
  const { data: funis, mutate: recarregarLista } = useSWR("/api/pipeline/funil/listar", fetcher);
  const nomeCampo = name || props.field.name;
  const labelCampo = props.label;
  const { values, setFieldValue } = useFormikContext();
  const valorInicial = funis?.find(x => x.padrao).id;

  const { isGerente } = useUsuario();
  const isSpotterFull = useTipoPlanoSpotter();

  const handleChangeFunil = (e, funil) => {
    if (funil !== undefined) {
      const valor = funil.id;
      setFieldValue("funilId", valor);
    }
  };

  return (
    <FormikAsyncSelect
      name={nomeCampo}
      id={nomeCampo}
      options={funis}
      getLabel={item => item.nome}
      value={values.funilId || valorInicial}
      label={labelCampo}
      onChange={handleChangeFunil}
      criarNovo={isSpotterFull && isGerente}
      urlCriarNovo={"/spotter/base-leads/funil/listar"}
      funcaoRecarregar={recarregarLista}
      fullWidth
      {...props}
    />
  );
}

AsyncSelectFunil.propTypes = {
  name: PropTypes.string,
  field: PropTypes.object,
  label: PropTypes.string,
};

export default AsyncSelectFunil;
