import React from "react";
import { useIntl } from "react-intl";

import { AsyncWithSearch } from "../../AsyncSelect";

const URL = "/api/pipeline/funil/listar";

function SelectFunil(props) {
  const intl = useIntl();

  return (
    <AsyncWithSearch
      label={intl.formatMessage({ defaultMessage: "Funil" })}
      url={URL}
      getLabel={item => item.nome}
      {...props}
    />
  );
}

export default SelectFunil;
