import React from "react";
import { useIntl } from "react-intl";

import { AsyncWithSearch } from "../../AsyncSelect";
import { LABEL_INATIVO } from "../../../_common/messages";

function SelectDor(props) {
  const intl = useIntl();
  const URL = "/api/pipeline/dor/listar";

  return (
    <AsyncWithSearch
      id="select-dor"
      label={intl.formatMessage({ defaultMessage: "Dor" })}
      url={URL}
      getLabel={dor => `${dor.descricao}${dor.ativo ? "" : ` ${intl.formatMessage(LABEL_INATIVO)}`}`}
      {...props}
    />
  );
}

export default SelectDor;
