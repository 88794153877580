import React from "react";
import PropTypes from "prop-types";
import PhoneForwarded from "@material-ui/icons/PhoneForwarded";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import BusinessIcon from "@material-ui/icons/Business";
import { Tooltip, withStyles } from "@material-ui/core";
import useSWR from "swr";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { FlagDDI } from "../pages/lead/components/Telefones";
import Comp from "./HoverActions";
import { useTipoPlanoSpotter, useUsuario, useWhatsApp } from "../hooks";
import { Flex, Tipografia } from "../_common";
import { formataLinkTelefone } from "../_common/utils/string";
import WhatsAppIcon from "../pages/whatsapp/WhatsAppIcon";
import TallosIcon from "../_common/icones/TallosIcon";
import { eRequestStatus } from "../pages/whatsapp/RegistrationRequest/constants";

const style = {
  icon: {
    marginRight: "5px",
    height: "20px",
    width: "20px",
  },
  flag: {
    margin: "0 6px 0",
    width: "21px",
    boxShadow: "1px 1px 4px 1px rgb(0, 0, 0, 0.2)",
  },
};

function Fone({ value, ddi, leadId, classes, tipo, linkMensagensTallos, setModalLigacaoIsOpen }) {
  const { id: userId, telefoniaIntegrada } = useUsuario();
  const whatsAppHabilitado = useSelector(state => state.whatsApp.habilitado);
  const isPublic = !userId;
  const { data: config } = useSWR(isPublic ? null : "/api/pipeline/lead/ConfiguracoesCadastro");
  const customLinkTelefone = config?.customLinkTelefone;
  const telefoneCompleto = ddi + value.replace(/\D/g, "");
  const telefone = value.replace(/\D/g, "");
  const intl = useIntl();
  const { statusSolicitacao } = useWhatsApp();
  const isSpotterFull = useTipoPlanoSpotter();

  const handleClickPhoneForwarded = () => {
    setModalLigacaoIsOpen(true);
  };
  return (
    <Comp
      copyValue={value}
      value={
        <Flex alignItems="center" justifyContent="center">
          {tipo === 1 && (
            <Tooltip title={intl.formatMessage({ defaultMessage: "Comercial" })}>
              <BusinessIcon className={classes.icon} />
            </Tooltip>
          )}
          {tipo === 2 && (
            <Tooltip title={intl.formatMessage({ defaultMessage: "Celular" })}>
              <PhoneIphoneIcon className={classes.icon} />
            </Tooltip>
          )}
          {tipo !== 2 && tipo !== 1 && <PhoneIcon className={classes.icon} />}
          <FlagDDI ddi={ddi} className={classes.flag} telefone={telefone} />
          {customLinkTelefone ? (
            <Tipografia urlExterna url={formataLinkTelefone(`${ddi}${value}`, customLinkTelefone)}>
              {value}
            </Tipografia>
          ) : (
            value
          )}
        </Flex>
      }
      classes={classes}
      ddi={ddi}
    >
      {linkMensagensTallos && (
        <Tooltip title={intl.formatMessage({ defaultMessage: "Abrir na RD Station Conversas" })}>
          <TallosIcon onClick={() => window.open(linkMensagensTallos, "_blank")} size={22} color="#457AB7" />
        </Tooltip>
      )}

      {(!whatsAppHabilitado || statusSolicitacao === eRequestStatus.cancelled) &&
        isSpotterFull && (
          <WhatsAppIcon
            onClick={() => window.open(`https://wa.me/${telefoneCompleto}`, "_blank")}
            size={19}
            color="#457AB7"
          />
        )}

      {telefoniaIntegrada &&
        leadId && (
          <>
            <PhoneForwarded onClick={() => handleClickPhoneForwarded()} fontSize="small" color="primary" />
          </>
        )}
    </Comp>
  );
}

Fone.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ddi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  leadId: PropTypes.string,
  tipo: PropTypes.string,
  linkMensagensTallos: PropTypes.string,
  classes: PropTypes.object,
  setModalLigacaoIsOpen: PropTypes.func,
};

export default withStyles(style)(Fone);
