import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";

import SelectCampoPersonalizado from "./SelectCampoPersonalizado";

function FormikSelectCampoPersonalizado({ campo, ...props }) {
  const [field, meta] = useField({ name: `camposPersonalizados.${campo.id}`, ...props });

  return <SelectCampoPersonalizado campo={campo} {...field} {...meta} {...props} />;
}

FormikSelectCampoPersonalizado.propTypes = {
  campo: PropTypes.object,
};

export default FormikSelectCampoPersonalizado;
