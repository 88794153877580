import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl } from "react-intl";
import { withStyles } from "@material-ui/core";
import TextMaskCep from "./TextMaskCep";
import { Flex, Loading } from "../_common";

function validateCep(cep) {
  let errors;
  if (!cep) return errors;

  const cepSemMask = cep
    .toString()
    .replace(/[- ]+/, "")
    .trim();

  const cepValido = /[0-9]{8}/g.test(cepSemMask);

  if (!cepValido) {
    errors = "CEP inválido";
  }
  return errors;
}

function InputCep({ name, hasValue, clearValue, classes, onBlur, isLoading, ...props }) {
  const intl = useIntl();
  return (
    <Flex className={classes.root}>
      <Field
        component={TextField}
        name={name}
        id={name}
        validate={validateCep}
        label={intl.formatMessage({ defaultMessage: "CEP" })}
        fullWidth
        {...props}
        style={{ flexGrow: 1 }}
        InputProps={{
          inputComponent: TextMaskCep,
          onBlur,
          endAdornment: isLoading ? <Loading className={classes.loading} isLoading size={24} /> : null,
          autoComplete: "nope",
        }}
      />
    </Flex>
  );
}

InputCep.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  hasValue: PropTypes.bool,
  clearValue: PropTypes.func,
  onBlur: PropTypes.func,
  isLoading: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles({
  root: {},
  loading: {
    height: "auto !important",
    width: "fit-content !important",
    position: "absolute !important",
    right: 6,
  },
})(InputCep);
