import { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { isObject } from "../_common/utils/object/object";

export function defaultGetElement(name) {
  let field = document.getElementsByName(name)[0] || document.querySelector(`[data-scroll-anchor="${name}"]`);
  if (field) field = field.type === "hidden" || field.hidden ? field.parentElement : field;
  return field;
}

function FormikErrorScroller({ onScroll = () => null, getElement = defaultGetElement }) {
  const formik = useFormikContext();

  useEffect(
    () => {
      if (!formik.submitCount) return;

      formik.validateForm().then(errors => {
        let fieldError = Object.keys(errors)[0];
        const error = errors[fieldError];

        if (Array.isArray(error)) {
          const firstErrorIndex = error.indexOf(error.find(Boolean));
          fieldError = `${fieldError}.${firstErrorIndex}`;
        } else if (isObject(error)) {
          fieldError = `${fieldError}.${Object.keys(errors[fieldError])[0]}`;
        }

        const field = getElement(fieldError);

        if (fieldError && field) {
          field.scrollIntoView({ block: "center", behavior: "smooth" });
          onScroll(fieldError);
        }
      });
    },
    [formik.submitCount]
  );

  return null;
}

FormikErrorScroller.propTypes = {
  onScroll: PropTypes.func,
  getElement: PropTypes.func,
};

export default FormikErrorScroller;
