import React from "react";
import { useField } from "formik";

import SelectFunil from "./SelectFunil";

function FormikSelectFunil(props) {
  const [field, meta] = useField({ name: "funilId", ...props });

  return <SelectFunil {...field} {...meta} {...props} />;
}

export default FormikSelectFunil;
