import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { DialogContent, DialogActions, Button } from "@material-ui/core";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";
import useSWR from "swr";

import { Loading } from "../../_common";
import FormRegra from "../../pages/configuracoes/RegrasSegmentacao/EditarRegra/FormRegra";
import {
  DEFAULT_REGRA,
  clearEmptyFields,
  initializeValues,
  makeValidateParametros,
} from "../../pages/configuracoes/RegrasSegmentacao/EditarRegra/useEditarRegra";
import SalvarPesquisa from "./SalvarPesquisa";
import { messagesBusca } from "../../pages/configuracoes/RegrasSegmentacao/constants";

ModalBuscaAvancada.propTypes = {
  onApply: PropTypes.func,
  onClear: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  regraSegmentacao: PropTypes.object,
  id: PropTypes.number,
};
function ModalBuscaAvancada({ onApply, onClear, onSave, onCancel, regraSegmentacao, id }) {
  const intl = useIntl();
  const { data: campos } = useSWR("/api/pipeline/campoPersonalizado/listasParaEdicao");
  const initialFormState = useMemo(() => initializeValues(regraSegmentacao, campos), [regraSegmentacao, campos]);
  const validateParametros = useMemo(() => makeValidateParametros(intl), [intl]);

  const salvarFiltro = async (values, actions) => {
    const valoresAtualizados = {
      ...values,
      parametros: {
        ...values.parametros,
        valorNegociacaoMinimo: montarValor(values.parametros.valorNegociacaoMinimo),
        valorNegociacaoMaximo: montarValor(values.parametros.valorNegociacaoMaximo),
      },
    };
    localStorage.setItem(`BuscaAvancadaSalva`, JSON.stringify(clearEmptyFields(valoresAtualizados)));
    onApply(clearEmptyFields(valoresAtualizados));

    actions.setSubmitting(false);
  };

  const limpar = async () => {
    onClear(DEFAULT_REGRA);
    onApply(clearEmptyFields(DEFAULT_REGRA));
    localStorage.removeItem(`BuscaAvancadaSalva`);
  };

  const montarValor = valor =>
    valor
      ? parseFloat(
          String(valor)
            .replace(/[^0-9,.]/g, "")
            .replace(",", ".")
        )
      : "";

  useEffect(() => {}, []);

  return (
    <React.Suspense fallback={<Loading isLoading />}>
      {!campos ? (
        <Loading isLoading />
      ) : (
        <Formik
          validate={validateParametros}
          initialValues={initialFormState}
          validateOnMount
          onReset={() => limpar()}
          onSubmit={(v, actions) => salvarFiltro(v, actions)}
        >
          {({ values, handleSubmit }) => (
            <Form>
              <DialogContent>
                <FormRegra />
              </DialogContent>

              <DialogActions>
                {onCancel && (
                  <Button id="btnCancelar-ModalBuscaAvancada" onClick={onCancel}>
                    {intl.formatMessage({ defaultMessage: "Cancelar" })}
                  </Button>
                )}

                {onSave && <SalvarPesquisa onSave={onSave} id={id} customMessages={messagesBusca} />}

                {onClear && (
                  <Button id="btnLimpar-ModalBuscaAvancada" type="reset">
                    {intl.formatMessage({ defaultMessage: "Limpar" })}
                  </Button>
                )}

                {onApply && (
                  <Button
                    id="btnAplicar-ModalBuscaAvancada"
                    onClick={() =>
                      Object.keys(clearEmptyFields(values).parametros).length > 1 ? handleSubmit() : onClear()
                    }
                    color="primary"
                  >
                    {intl.formatMessage({ defaultMessage: "Aplicar" })}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </React.Suspense>
  );
}

export default ModalBuscaAvancada;
