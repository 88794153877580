import React from "react";
import PropTypes from "prop-types";

import { usePermission } from "../../hooks";
import ActionButton from "./ActionButton";

ActionWithClaim.propTypes = {
  claim: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  leads: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        grupos: PropTypes.arrayOf(PropTypes.number),
        preVendedorId: PropTypes.number,
      })
    ),
    PropTypes.shape({
      grupos: PropTypes.arrayOf(PropTypes.number),
      preVendedorId: PropTypes.number,
    }),
  ]),
};

function ActionWithClaim({ claim = true, leads, ...props }) {
  const hasPermission = usePermission(claim, leads);
  if (!hasPermission) return null;

  return <ActionButton {...props} />;
}

export default ActionWithClaim;
