import React from "react";
import { useIntl } from "react-intl";

import { AsyncWithSearch } from "../../AsyncSelect";

const URL = "/api/pipeline/etapa/DatasReferencia";

function SelectDataReferencia(props) {
  const intl = useIntl();

  return <AsyncWithSearch label={intl.formatMessage({ defaultMessage: "Referência" })} url={URL} {...props} />;
}

export default SelectDataReferencia;
