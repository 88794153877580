import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PhoneIcon from "@material-ui/icons/Phone";
import { Tooltip, withStyles, Typography } from "@material-ui/core";
import useSWR from "swr";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import cn from "classnames";
import FileCopyOutlined from "@material-ui/icons/FileCopyOutlined";

import { FlagDDI } from "../pages/lead/components/Telefones";
import { useTipoPlanoSpotter, useUsuario, useWhatsApp } from "../hooks";
import { Flex, Tipografia, createSnackbar } from "../_common";
import { formataLinkTelefone, copyToClipboard } from "../_common/utils/string";
import WhatsAppIcon from "../pages/whatsapp/WhatsAppIcon";
import TallosIcon from "../_common/icones/TallosIcon";
import { eRequestStatus } from "../pages/whatsapp/RegistrationRequest/constants";

const ENUM_TIPO_TELEFONE = {
  COMERCIAL: 1,
  CELULAR: 2,
};

function FoneNovo({
  value,
  ddi,
  leadId,
  classes,
  tipo,
  linkMensagensTallos,
  setModalLigacaoIsOpen,
  telefonePrincipal,
}) {
  const { id: userId, telefoniaIntegrada } = useUsuario();
  const whatsAppHabilitado = useSelector(state => state.whatsApp.habilitado);
  const isPublic = !userId;
  const { data: config } = useSWR(isPublic ? null : "/api/pipeline/lead/ConfiguracoesCadastro");
  const customLinkTelefone = config?.customLinkTelefone;
  const telefoneCompleto = ddi + value.replace(/\D/g, "");
  const intl = useIntl();
  const { statusSolicitacao } = useWhatsApp();
  const isSpotterFull = useTipoPlanoSpotter();
  const [labelTipoTelefone, setLabelTipoTelefone] = useState("");
  const classeIcone = cn(classes.icon, telefonePrincipal ? classes.principal : classes.secundario);

  const validarLinkTelefone = telefoniaIntegrada && leadId;

  const handleClickPhoneForwarded = () => {
    if (validarLinkTelefone) setModalLigacaoIsOpen(true);
  };

  const copiar = () => {
    const validatedValue = ddi ? `+${ddi} ${value}` : value;
    copyToClipboard(validatedValue);
    createSnackbar(intl.formatMessage({ defaultMessage: "Copiado" }));
  };

  useEffect(
    () => {
      switch (parseInt(tipo, 10)) {
        case ENUM_TIPO_TELEFONE.COMERCIAL:
          setLabelTipoTelefone(intl.formatMessage({ defaultMessage: "Comercial" }));
          break;
        case ENUM_TIPO_TELEFONE.CELULAR:
          setLabelTipoTelefone(intl.formatMessage({ defaultMessage: "Celular" }));
          break;
        default:
          setLabelTipoTelefone(intl.formatMessage({ defaultMessage: "Outros" }));
          break;
      }
    },
    [tipo]
  );

  return (
    <Flex className={classes.root}>
      <Flex alignItems="center" className={classes.containerTelefone}>
        <Tooltip title={telefonePrincipal ? intl.formatMessage({ defaultMessage: "Telefone principal" }) : ""}>
          <PhoneIcon className={classeIcone} />
        </Tooltip>
        <FlagDDI ddi={ddi} className={classes.flag} />
        {customLinkTelefone ? (
          <Tipografia urlExterna url={formataLinkTelefone(`${ddi}${value}`, customLinkTelefone)}>
            {value}
          </Tipografia>
        ) : (
          <Typography className={cn(classes.link, classes.principal)} onClick={() => handleClickPhoneForwarded()}>
            <Flex alignItems="center">
              {value}
              <Typography className={classes.labelTipoTelefone}>{labelTipoTelefone}</Typography>
            </Flex>
          </Typography>
        )}
      </Flex>
      <Flex justifyContent="end" className={classes.containerAcoes}>
        <Tooltip title={intl.formatMessage({ defaultMessage: "Copiar telefone" })} placement="bottom">
          <FileCopyOutlined
            id="fileCopyOutlined"
            onClick={copiar}
            fontSize="small"
            className={cn(classes.iconeEspacamento, classes.iconeAcao, classes.link)}
          />
        </Tooltip>
        {linkMensagensTallos && (
          <Tooltip title={intl.formatMessage({ defaultMessage: "Abrir na RD Station Conversas" })}>
            <TallosIcon onClick={() => window.open(linkMensagensTallos, "_blank")} size={22} className={classes.link} />
          </Tooltip>
        )}

        {(!whatsAppHabilitado || statusSolicitacao === eRequestStatus.cancelled) &&
          isSpotterFull && (
            <WhatsAppIcon
              onClick={() => window.open(`https://wa.me/${telefoneCompleto}`, "_blank")}
              className={cn(classes.link, classes.iconeAcao)}
            />
          )}
      </Flex>
    </Flex>
  );
}

FoneNovo.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ddi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  leadId: PropTypes.string,
  tipo: PropTypes.string,
  linkMensagensTallos: PropTypes.string,
  classes: PropTypes.object,
  setModalLigacaoIsOpen: PropTypes.func,
  telefonePrincipal: PropTypes.bool,
};

export default withStyles({
  root: {
    marginBottom: 8,
    "&:hover $containerAcoes": {
      visibility: "visible",
    },
  },
  iconeEmail: { marginRight: 4 },
  principal: { color: "#457AB7" },
  secundario: { color: "rgba(0, 0, 0, 0.38)" },
  containerTelefone: {
    width: "70%",
  },
  containerAcoes: {
    width: "30%",
    visibility: "hidden",
  },
  icon: {
    marginRight: "5px",
    height: "20px",
    width: "20px",
  },
  flag: {
    margin: "0 6px 0",
    width: "21px",
    boxShadow: "1px 1px 4px 1px rgb(0, 0, 0, 0.2)",
  },
  link: {
    cursor: "pointer",
  },
  labelTipoTelefone: {
    paddingLeft: 4,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "14px",
    color: "rgba(0, 0, 0, 0.38)",
  },
  iconeEspacamento: {
    marginRight: "5px",
  },
  iconeAcao: {
    height: "20px",
    width: "20px",
    color: "rgba(0, 0, 0, 0.38)",
    fill: "rgba(0, 0, 0, 0.38)",
    "&:hover": {
      visibility: "visible",
      color: "#457AB7",
      fill: "#457AB7",
    },
  },
})(FoneNovo);
