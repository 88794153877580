import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { DialogContent, DialogActions, Button, Dialog, DialogTitle, DialogContentText } from "@material-ui/core";
import { useIntl, defineMessages } from "react-intl";
import { useFormikContext, Formik, Field } from "formik";
import useSWR from "swr";
import { TextField } from "formik-material-ui";

import { AjaxBlackout, createSnackbarAPIException } from "../../_common";
import { useToggle, useUsuario } from "../../hooks";
import { makeValidateNome, saveRegra } from "../../pages/configuracoes/RegrasSegmentacao/EditarRegra/useEditarRegra";

SalvarPesquisa.propTypes = {
  onSave: PropTypes.func.isRequired,
  id: PropTypes.number,
  customMessages: PropTypes.object,
};
export default function SalvarPesquisa({ onSave, id, customMessages }) {
  const messagesDefault = defineMessages({
    nome: { defaultMessage: "Nome da pesquisa" },
    nomeObrigatorio: { defaultMessage: "É preciso dar um nome a pesquisa" },
    nomeNaoUnico: { defaultMessage: "Já existe pesquisa com esse nome" },
    nomeExiste: { defaultMessage: "Este nome já existe, escolha outro." },
    aplicarAlteracoes: { defaultMessage: "Deseja aplicar as alterações nessa pesquisa?" },
  });

  const messages = customMessages === undefined ? messagesDefault : customMessages;

  const intl = useIntl();
  const { values, isValid } = useFormikContext();
  const valuesMinusId = useMemo(
    () => {
      const { id: _, ...minusId } = values;
      return minusId;
    },
    [values]
  );
  const { data: regrasGerais, mutate } = useSWR("/api/pipeline/SegmentacaoRegra/Listar");
  const { id: usuarioId } = useUsuario();

  const regrasUsuario = regrasGerais?.filter(r => r.usuarioId === usuarioId);
  const validate = useMemo(() => makeValidateNome(valuesMinusId, regrasUsuario, intl, messages.nomeNaoUnico), [
    valuesMinusId,
    regrasUsuario,
    intl,
    messages.nomeNaoUnico,
  ]);
  const [open, toggle] = useToggle(false);

  async function save({ nome }) {
    AjaxBlackout.Show();
    const { id: _, ...newValues } = { ...values, nome, usuarioId };

    try {
      const novaRegra = await saveRegra(newValues);
      mutate(regrasVelhas => regrasVelhas.concat({ ...novaRegra, nome, usuarioId }), false);
      toggle();
      onSave(novaRegra);
    } catch (err) {
      createSnackbarAPIException(err);
    } finally {
      AjaxBlackout.Hide();
    }
  }

  return (
    <>
      <Button disabled={!isValid} id="btnSalvar-SalvarPesquisa" onClick={toggle}>
        {intl.formatMessage({ defaultMessage: "Salvar" })}
      </Button>

      <Dialog open={open} disableBackdropClick maxWidth="md" fullWidth>
        <DialogTitle>{intl.formatMessage(messages.nome)}</DialogTitle>

        <Formik onSubmit={save} onReset={toggle} initialValues={{ nome: "" }}>
          {({ handleReset, handleSubmit }) => (
            <>
              <DialogContent>
                <DialogContentText>
                  {id ? intl.formatMessage(messages.nomeObrigatorio) : intl.formatMessage(messages.aplicarAlteracoes)}
                </DialogContentText>

                <Field
                  label={intl.formatMessage(messages.nome)}
                  component={TextField}
                  autoFocus
                  validate={validate}
                  name="nome"
                  fullWidth
                />
              </DialogContent>

              <DialogActions>
                <Button color="primary" onClick={handleSubmit}>
                  {intl.formatMessage({ defaultMessage: "Salvar" })}
                </Button>

                <Button onClick={handleReset}>{intl.formatMessage({ defaultMessage: "Cancelar" })}</Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
