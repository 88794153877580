import React from "react";
import { useField } from "formik";

import SelectVendedor from "./SelectVendedor";

function FormikSelectVendedor(props) {
  const [field, meta] = useField({ name: "vendedorId", ...props });

  return <SelectVendedor {...field} {...meta} {...props} />;
}

export default FormikSelectVendedor;
