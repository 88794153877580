import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import createSnackAPIException from "../_common/utils/snackbar/createSnackbarAPIException";
import { AjaxBlackout } from "../_common";

ExactFormik.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func,
  beforeSubmit: PropTypes.func,
  afterSubmit: PropTypes.func,
};

ExactFormik.defaultProps = {
  onError: createSnackAPIException,
};

export default function ExactFormik({
  onSubmit,
  beforeSubmit = AjaxBlackout.Show,
  afterSubmit = AjaxBlackout.Hide,
  onError,
  ...props
}) {
  const submit = useCallback(
    async (values, actions) => {
      await beforeSubmit();
      try {
        await onSubmit(values, actions);
      } catch (err) {
        actions.setSubmitting(false);
        await onError(err);
      } finally {
        await afterSubmit();
      }
    },
    [beforeSubmit, afterSubmit, onSubmit, onError]
  );

  const handleSubmit = useCallback(
    (values, actions) => {
      submit(values, actions);
    },
    [submit]
  );

  return <Formik onSubmit={handleSubmit} {...props} />;
}
