import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

function EmptyState({ text, classes }) {
  return <div className={classes.root}>{text}</div>;
}

EmptyState.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  classes: PropTypes.object,
};

export default withStyles({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#AAA",
  },
})(EmptyState);
