import React from "react";
import { useIntl } from "react-intl";
import { AsyncWithSearch } from "../../AsyncSelect";

function SelectMercado(props) {
  const intl = useIntl();
  const URL = "/api/pipeline/mercado/ListarMercado";

  return (
    <AsyncWithSearch
      id="select-mercado"
      label={intl.formatMessage({ defaultMessage: "Mercado" })}
      url={URL}
      {...props}
    />
  );
}

export default SelectMercado;
