import React from "react";
import PropTypes from "prop-types";

const FeedbackRollout = ({ classe }) => <div className={classe} />;

FeedbackRollout.propTypes = {
  classe: PropTypes.string,
};

export default FeedbackRollout;
