import React from "react";
import { useField } from "formik";

import SelectConfirmacaoReuniao from "./SelectConfirmacaoReuniao";

function FormikSelectConfirmacaoReuniao(props) {
  const [field, meta] = useField({ name: "reuniaoConfirmacao", ...props });

  return <SelectConfirmacaoReuniao {...field} {...meta} {...props} />;
}

export default FormikSelectConfirmacaoReuniao;
