import React from "react";
import PropTypes from "prop-types";
import { Tooltip, IconButton as MIconButton } from "@material-ui/core";

IconButton.propTypes = { icon: PropTypes.node.isRequired, tooltip: PropTypes.string };

function IconButton({ icon, tooltip = "", ...props }) {
  return (
    <Tooltip title={tooltip}>
      <span>
        <MIconButton style={{ height: "100%" }} {...props}>
          {icon}
        </MIconButton>
      </span>
    </Tooltip>
  );
}

export default IconButton;
