import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import useSWR from "swr";

import SelectEstado from "./SelectEstado";

FormikSelectEstado.propTypes = { paises: PropTypes.arrayOf(PropTypes.number) };
function FormikSelectEstado({ paises = [], ...props }) {
  const [field, meta, helpers] = useField({ name: "estadoId", ...props });
  const { data: options } = useSWR("/api/pipeline/estado/listar");

  const estados = useMemo(() => (paises.length ? options?.filter(option => paises.includes(option.paisId)) : options), [
    paises,
    options,
  ]);

  useEffect(
    () => {
      if (!estados) return;
      const valueArray = [].concat(field.value);
      const filtradas = valueArray.filter(estado => estados.some(({ id }) => id === estado));
      if (filtradas.length !== valueArray.length)
        helpers.setValue(Array.isArray(field.value) ? filtradas : filtradas[0] ?? null);
    },
    [estados, helpers.setValue]
  );

  return <SelectEstado {...field} {...meta} options={estados} {...props} />;
}

export default FormikSelectEstado;
