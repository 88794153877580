import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import useSWR from "swr";
import { useFormikContext } from "formik";
import { useUsuario } from "../../hooks";
import { FormikAsyncSelect } from "../AsyncSelect";

const fetcher = url => axios.get(url).then(res => res.data);

function AsyncSelectPreVendedor({ name, ...props }) {
  const { data: preVendedores, mutate: recarregarLista } = useSWR(
    "/api/pipeline/usuario1/listar?tipo=PreVendedor&somenteAtivos=true",
    fetcher
  );
  const nomeCampo = props.field.name;
  const labelCampo = props.label;
  const { values, setFieldValue } = useFormikContext();
  const { isGerente } = useUsuario();

  const handleChangePreVendedor = (e, preVendedor) => {
    if (preVendedor) {
      const valor = preVendedor.id;
      setFieldValue("preVendedorId", valor);
    }
  };

  return (
    <FormikAsyncSelect
      name={nomeCampo}
      id={nomeCampo}
      options={preVendedores}
      value={values.preVendedorId}
      label={labelCampo}
      onChange={handleChangePreVendedor}
      criarNovo={isGerente}
      urlCriarNovo={"/spotter/equipe/prevendedor"}
      funcaoRecarregar={recarregarLista}
      enableSearch
      fullWidth
    />
  );
}

AsyncSelectPreVendedor.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  field: PropTypes.object,
};

export default AsyncSelectPreVendedor;
