import React from "react";
import { useIntl } from "react-intl";

import { AsyncWithSearch } from "../../AsyncSelect";

function SelectPais(props) {
  const intl = useIntl();
  const URL = "/api/pipeline/pais/listar";

  return (
    <AsyncWithSearch id="select-pais" label={intl.formatMessage({ defaultMessage: "País" })} url={URL} {...props} />
  );
}

export default SelectPais;
