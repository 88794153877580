import React from "react";
import { useIntl } from "react-intl";

import { AsyncWithSearch } from "../../AsyncSelect";
import { LABEL_INATIVO } from "../../../_common/messages";

function SelectProduto(props) {
  const intl = useIntl();
  const URL = "/api/pipeline/produto/listar";

  return (
    <AsyncWithSearch
      id="select-produto"
      label={intl.formatMessage({ defaultMessage: "Produto sugerido" })}
      url={URL}
      getLabel={prod => `${prod.descricao}${prod.ativo ? "" : ` ${intl.formatMessage(LABEL_INATIVO)}`}`}
      {...props}
    />
  );
}

export default SelectProduto;
