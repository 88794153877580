import React from "react";
import { useIntl } from "react-intl";
import { useFormikContext } from "formik";
import { Button, CircularProgress } from "@material-ui/core";

function FormikSubmit({ ...props }) {
  const intl = useIntl();
  const { dirty, isSubmitting } = useFormikContext();

  if (isSubmitting)
    return (
      <Button disabled {...props}>
        {intl.formatMessage({ defaultMessage: "Salvando" })} <CircularProgress size={10} style={{ marginLeft: 4 }} />
      </Button>
    );

  return (
    <Button color="primary" type="submit" disabled={!dirty} {...props}>
      {dirty ? intl.formatMessage({ defaultMessage: "Salvar" }) : intl.formatMessage({ defaultMessage: "Salvo" })}
    </Button>
  );
}

export default FormikSubmit;
