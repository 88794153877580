import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  TextField,
  MenuItem,
  Checkbox,
  CircularProgress,
  Typography,
  ListSubheader,
  ListItemText,
  IconButton,
  ListItem,
  ListItemIcon,
  Divider,
  ClickAwayListener,
} from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import AddCircle from "@material-ui/icons/AddCircle";
import { Tooltip } from "react-tippy";
import useSWR from "swr";
import { defineMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import axios from "axios";
import { FixedSizeList as List } from "react-window";

import useSearch from "./useSearch";
import { truncateString } from "../../_common/utils/string";
import { IdsInlineManual } from "../../_common/utils/constants/inlineManual";
import { Flex } from "../../_common";

const TYPES = {
  MULTIPLE: "multiple",
  NULLABLE: "nullable",
  SINGULAR: "singular",
};

const SELECT_ALL = "Todos";
const SELECT_NONE = "Nenhum";
const TAMANHO_CARACTER = 9;
const BUSCA = defineMessage({ defaultMessage: "Busca" });

const DEFAULT_GET_ID = item => item.id;
const DEFAULT_GET_LABEL = item => item.descricao;
const DEFAULT_TRANSFORM_PAYLOAD = item => item;
const DEFAULT_FILTER = x => x;
const DEFAULT_GET_ACTIVE = item => item.ativo;
let desabilitar = false;

function removeFieldProps(props) {
  const {
    initialError,
    initialTouched,
    initialValue,
    touched,
    validate,
    error,
    disabled,
    helperText,
    ...newProps
  } = props;

  desabilitar = disabled;
  return { ...newProps, error: !!error, helperText: helperText || error };
}

function reduzRotulo(tipo, valor) {
  if (tipo === TYPES.MULTIPLE) return Boolean(valor.length);
  return ["number", "boolean"].includes(typeof valor) || Boolean(valor);
}

function AsyncSelect({
  url = null,
  type = TYPES.SINGULAR,
  enableSearch = false,
  searchLabel = BUSCA,
  truncateDescriptionAt = Infinity,
  options = null,
  noOptionText,
  criarNovo = false,
  urlCriarNovo,
  classes,
  getId = DEFAULT_GET_ID,
  getLabel = DEFAULT_GET_LABEL,
  transformPayload = DEFAULT_TRANSFORM_PAYLOAD,
  getInputLabel,
  filter = DEFAULT_FILTER,
  getActive = DEFAULT_GET_ACTIVE,
  enableClearValue,
  recarregarLista = false,
  carregarSomenteAoAbrir = false,
  funcaoRecarregar,
  desabilitarOpcoesPorRegra = false,
  exibirRotuloInativo = true,
  validarLabel = false,
  ...props
}) {
  const intl = useIntl();
  const optionAll = { id: SELECT_ALL, descricao: intl.formatMessage({ defaultMessage: "Todos" }), ativo: true };
  const optionNone = {
    id: SELECT_NONE,
    descricao: intl.formatMessage({ defaultMessage: "Nenhuma das opções" }),
    ativo: true,
  };
  const maxVisibleItems = 15;
  const defaultHeight = 500;
  const itemHeight = 45;
  const paddingOptionItem = type !== TYPES.MULTIPLE ? "11px 16px" : "0";
  const [loading, setLoading] = useState(true);
  const [abrirSelect, setAbrirSelect] = useState();
  const [pesquisar, setPesquisar] = useState(true);
  const [itens, setItens] = useState([]);
  const [additionalItens, setAdditionalItens] = useState([]);
  const { filteredItems, searchTerm, setSearchTerm, isSearching } = useSearch(enableSearch, itens);
  const textFieldProps = removeFieldProps(props);
  const { data: dataOptions } = useSWR(() => (options || carregarSomenteAoAbrir ? null : url));
  const listRef = useRef(null);
  const [listWidth, setListWidth] = useState(0);

  useEffect(
    () => {
      if (type !== TYPES.MULTIPLE) return;
      if (filteredItems.length === 0) setAdditionalItens([]);
      else if (additionalItens.length === 0 && itens.length) {
        setAdditionalItens([optionAll, ...additionalItens]);
      }
    },
    [filteredItems]
  );

  useEffect(() => {
    if (type === TYPES.NULLABLE) {
      setAdditionalItens([optionNone, ...additionalItens]);
    }
  }, []);

  useEffect(
    () => {
      if (options) {
        setItens(transformPayload(options).filter(filter));
        setLoading(false);
      } else if (dataOptions) {
        setLoading(false);
        setItens(transformPayload(dataOptions).filter(filter));
      } else if (carregarSomenteAoAbrir) {
        setLoading(false);
      }

      if (type === TYPES.MULTIPLE && (dataOptions?.length > 0 || options?.length > 0) && additionalItens.length === 0) {
        setAdditionalItens([optionAll, ...additionalItens]);
      }
      setAbrirSelect(false);
      setPesquisar(true);
    },
    [dataOptions, options, filter]
  );

  const open = async e => {
    setAbrirSelect(true);
    if (pesquisar) {
      if (e.value === undefined && recarregarLista && urlCriarNovo) {
        const { data: novaLista } = await axios.get(url);
        setItens(transformPayload(novaLista).filter(filter));
        setLoading(false);
      }

      if (funcaoRecarregar) {
        funcaoRecarregar();
        setLoading(false);
      }
    }
    setPesquisar(true);
  };

  const validarCliqueFora = () => {
    setAbrirSelect(false);
    setPesquisar(false);
  };

  const habilitarItem = item => {
    const desabilita = item.ativo !== undefined && !item.ativo;
    return desabilita;
  };

  const obterItemId = item => {
    const ID_ITEM_AGENDAMENTO = 10;
    const DESCRICAO_AGENDAMENTO = "Agendamento";
    if (item?.descricao === DESCRICAO_AGENDAMENTO && item?.id === ID_ITEM_AGENDAMENTO) {
      return IdsInlineManual.WORKFLOW_AGENDAMENTO_OPTION;
    }
    return null;
  };

  function getIdWithNewOptions(item) {
    if (item?.id === SELECT_ALL || item?.id === SELECT_NONE) {
      return item?.id;
    }
    return getId(item);
  }

  function getLabelWithNewOptions(item) {
    if (item?.id === SELECT_ALL || item?.id === SELECT_NONE) {
      return item?.descricao;
    }

    return getLabel(item);
  }

  function closeMenu() {
    const escapedName = (props.name || "")
      .replace(/\./g, "\\.")
      .replace(/\[/g, "\\[")
      .replace(/\]/g, "\\]");
    const selector = `#menu-${escapedName}`;
    const firstChild = document?.querySelector(selector).firstElementChild;
    if (firstChild) {
      firstChild.click();
    }
  }

  function createSyntheticEvent(newValue) {
    return {
      target: { name: props.name, value: newValue },
    };
  }

  function toggleMultipleSelection(itemId) {
    return props.value.includes(itemId) ? props.value.filter(id => id !== itemId) : [...props.value, itemId];
  }

  function change(e) {
    if (type !== TYPES.MULTIPLE) {
      handleOnChange(e.target.value);
      closeMenu();
      return;
    }

    if (e.target.value.includes(SELECT_ALL)) {
      const itensFiltrados = isSearching ? filteredItems.map(getIdWithNewOptions) : itens.map(getIdWithNewOptions);
      const allSelected = itensFiltrados.every(id => props.value.includes(id));

      if (isSearching)
        e.target.value = allSelected
          ? props.value.filter(id => !itensFiltrados.includes(id))
          : [...new Set([...props.value, ...itensFiltrados])];
      else e.target.value = allSelected ? [] : itensFiltrados;
    }

    handleOnChange(e.target.value);
  }

  function handleOnChange(newValue) {
    const selectedItems = Array.isArray(newValue)
      ? itens.filter(item => newValue.includes(getIdWithNewOptions(item)))
      : itens.find(item => getIdWithNewOptions(item) === newValue) || null;
    props.onChange({ target: { name: props.name, value: selectedItems ? newValue : undefined } }, selectedItems);
  }

  const handleClick = (e, item) => {
    e.stopPropagation();
    const itemId = getIdWithNewOptions(item);
    const newValue = type === TYPES.MULTIPLE ? toggleMultipleSelection(itemId) : itemId;

    const syntheticEvent = createSyntheticEvent(newValue);
    change(syntheticEvent);
  };

  const validateLabel = value => {
    if (typeof value === "string" || typeof value === "number") return value !== "";
    return value.length > 0;
  };

  return (
    <ClickAwayListener onClickAway={validarCliqueFora}>
      <TextField
        className={classes.root}
        select
        open={abrirSelect}
        SelectProps={{
          MenuProps: {
            classes: { paper: classes.menu },
            onExited: () => setSearchTerm(""),
          },
          renderValue: values => {
            const labelGetter = getInputLabel || getLabelWithNewOptions;
            if (type === TYPES.MULTIPLE) {
              return props.value.length === itens.length
                ? intl.formatMessage({
                    defaultMessage: "Todos",
                  })
                : (options ?? itens)
                    .filter(item => values.includes(getIdWithNewOptions(item)))
                    .map(labelGetter)
                    .join(", ");
            }
            const item = (options ?? itens).find(i => getIdWithNewOptions(i) === values);
            return item ? labelGetter(item) : undefined;
          },
          multiple: type === TYPES.MULTIPLE,
          IconComponent: loading ? CircularProgress : ArrowDropDown,
          disabled: desabilitar,
          ...props.SelectProps,
        }}
        InputLabelProps={{ shrink: reduzRotulo(type, props.value) }}
        InputProps={{
          endAdornment:
            enableClearValue && !!props.value ? (
              <IconButton size="small" className={classes.cleanButton} onClick={e => props.onChange(e, null)}>
                <CloseIcon />
              </IconButton>
            ) : null,
        }}
        {...textFieldProps}
        label={validarLabel && validateLabel(textFieldProps.value) ? " " : textFieldProps.label}
        onClick={({ target }) => open(target)}
      >
        {enableSearch && (
          <ListSubheader className={classes.searchWrapper}>
            <TextField
              className={classes.searchField}
              label={typeof searchLabel === "string" ? searchLabel : intl.formatMessage(searchLabel)}
              value={searchTerm}
              onChange={({ target }) => setSearchTerm(target.value)}
              onClick={e => e.stopPropagation()}
              placeholder={intl.formatMessage({ defaultMessage: "Pesquisar" })}
              InputLabelProps={{ shrink: true }}
              id="options-filter-input"
            />
            {isSearching &&
              !filteredItems.length && (
                <Typography color="textSecondary" onClick={e => e.stopPropagation()}>
                  {intl.formatMessage({ defaultMessage: "Nenhum resultado encontrado" })}
                </Typography>
              )}
          </ListSubheader>
        )}
        <div ref={listRef}>
          <List
            height={
              filteredItems.length + additionalItens.length > maxVisibleItems
                ? defaultHeight
                : (filteredItems.length + additionalItens.length) * itemHeight
            }
            itemCount={filteredItems.length + additionalItens.length}
            itemSize={itemHeight}
            itemData={additionalItens.concat(filteredItems)}
            style={{ width: listWidth > 0 ? listWidth : "100%" }}
          >
            {({ data, index, style }) => {
              const item = data[index];
              const labelGetter = getLabelWithNewOptions(item).length || getInputLabel(item).length;
              if (labelGetter * TAMANHO_CARACTER > listRef?.current?.offsetWidth) {
                setListWidth(labelGetter * TAMANHO_CARACTER);
              }
              return (
                <div
                  style={{ height: itemHeight, width: "100%" }}
                  className={classes.menuItem}
                  onMouseDown={e => handleClick(e, item)}
                >
                  <MenuItem
                    itemID={obterItemId(item)}
                    value={getIdWithNewOptions(item)}
                    key={getIdWithNewOptions(item)}
                    id={`options-item-${index}`}
                    disabled={desabilitarOpcoesPorRegra ? habilitarItem(item) : false}
                    onMouseDown={e => handleClick(e, item)}
                    style={{ ...style, padding: 0 }}
                  >
                    <Flex alignItems="center" style={{ height: itemHeight, width: "auto" }}>
                      {type === TYPES.MULTIPLE && (
                        <Checkbox
                          onMouseDown={e => handleClick(e, item)}
                          checked={
                            (isSearching
                              ? filteredItems.every(itemFiltrado =>
                                  props.value.includes(getIdWithNewOptions(itemFiltrado))
                                )
                              : props.value.length === itens.length) || props.value.includes(getIdWithNewOptions(item))
                          }
                        />
                      )}
                      {getLabelWithNewOptions(item) !== undefined &&
                      getLabelWithNewOptions(item).length > truncateDescriptionAt ? (
                        <Tooltip title={getLabelWithNewOptions(item)}>
                          <ListItemText
                            onMouseDown={e => handleClick(e, item)}
                            style={{ padding: paddingOptionItem }}
                            secondary={
                              exibirRotuloInativo && getActive(item) === false
                                ? intl.formatMessage({ defaultMessage: "Inativo" })
                                : undefined
                            }
                          >
                            {truncateString(getLabelWithNewOptions(item), truncateDescriptionAt)}
                          </ListItemText>
                        </Tooltip>
                      ) : (
                        <ListItemText
                          onMouseDown={e => handleClick(e, item)}
                          style={{ padding: paddingOptionItem }}
                          secondary={
                            exibirRotuloInativo && getActive(item) === false
                              ? intl.formatMessage({ defaultMessage: "Inativo" })
                              : undefined
                          }
                        >
                          {getLabelWithNewOptions(item)}
                        </ListItemText>
                      )}
                    </Flex>
                  </MenuItem>
                </div>
              );
            }}
          </List>
        </div>
        {itens.length === 0 &&
          noOptionText && (
            <MenuItem className={classes.noOptionText} disabled id="options-empty">
              <ListItemText>{noOptionText}</ListItemText>
            </MenuItem>
          )}

        {criarNovo && (
          <div>
            <Divider />
            <MenuItem style={{ display: "flex", flexDirection: "row" }}>
              <ListItem
                disableRipple
                disableTouchRipple
                button
                component={Link}
                to={urlCriarNovo}
                target="_blank"
                style={{ display: "flex", flexDirection: "row", paddingLeft: "0px", backgroundColor: "#f4f5f700" }}
              >
                <ListItemIcon id="2" className={classes.botaoCriarNovo}>
                  <AddCircle style={{ color: "#457AB7" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={classes.labelCriarNovo}>
                      {intl.formatMessage({ defaultMessage: "Criar novo" })}
                    </Typography>
                  }
                  className={classes.labelCriarNovo}
                />
              </ListItem>
            </MenuItem>
          </div>
        )}
      </TextField>
    </ClickAwayListener>
  );
}

AsyncSelect.defaultProps = {
  value: "",
};

AsyncSelect.propTypes = {
  url: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["multiple", "nullable", "singular"]),
  name: PropTypes.string,
  id: PropTypes.string,
  enableSearch: PropTypes.bool,
  searchLabel: PropTypes.string,
  truncateDescriptionAt: PropTypes.number,
  getId: PropTypes.func,
  getInputLabel: PropTypes.func,
  getActive: PropTypes.func,
  filter: PropTypes.func,
  getLabel: PropTypes.func,
  classes: PropTypes.object,
  SelectProps: PropTypes.object,
  noOptionText: PropTypes.string,
  transformPayload: PropTypes.func,
  options: PropTypes.array,
  getDisabled: PropTypes.func,
  enableClearValue: PropTypes.bool,
  criarNovo: PropTypes.bool,
  urlCriarNovo: PropTypes.string,
  recarregarLista: PropTypes.bool,
  carregarSomenteAoAbrir: PropTypes.bool,
  funcaoRecarregar: PropTypes.func,
  desabilitarOpcoesPorRegra: PropTypes.bool,
  exibirRotuloInativo: PropTypes.bool,
  validarLabel: PropTypes.bool,
};

export default withStyles({
  root: {
    marginRight: 4,
    marginLeft: 4,
  },
  noOptionText: { maxHeight: 15 },
  searchWrapper: {
    pointerEvents: "none",
    outline: "none",
    background: "#fff",
    position: "relative",
  },
  searchField: {
    pointerEvents: "auto",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: 8,
    marginBottom: 16,
  },
  botaoCriarNovo: {
    marginRight: "0px !important",
  },
  labelCriarNovo: {
    color: "#457AB7 !important",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  cleanButton: {
    position: "absolute",
    right: 10,
    bottom: -10,
  },
  menu: {
    maxWidth: 700,
    width: "-webkit-fill-available",
    overflow: "auto",
    display: "grid",
    "& li": {
      outline: 0,
      width: "100%",
    },
  },
  menuItem: {
    cursor: "pointer",
    "& li": {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  },
})(AsyncSelect);
